<template>
  <div class="map-container">
    <div class="d-flex justify-content-between location-form-con gap-2">
        <div class="form-group location-searchbox-con">
          <GMapAutocomplete @keydown="stopEnterSubmitForm" class="form-control location-searchbox" :value="placename" @place_changed="initMarker"></GMapAutocomplete>
          <div class="search-actions">
            <span class="search-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.76 13.27L20.49 19L19 20.49L13.27 14.76C12.2 15.53 10.91 16 9.5 16C5.91 16 3 13.09 3 9.5C3 5.91 5.91 3 9.5 3C13.09 3 16 5.91 16 9.5C16 10.91 15.53 12.2 14.76 13.27ZM9.5 5C7.01 5 5 7.01 5 9.5C5 11.99 7.01 14 9.5 14C11.99 14 14 11.99 14 9.5C14 7.01 11.99 5 9.5 5Z" fill="#49454F"/>
              </svg>
            </span>
            <span class="clear-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" @click="clearPlacename()">
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="black"/>
              </svg>
            </span>
          </div>
        </div>
        <div class="form-group col-4 col-sm-3 p-0 numberbox">
          <Dropdown class="w-100" :items="radiusObj" :unit="radiusUnit" 
          @item-selected="onRadiusChange" @extra-event="onExtraEvent" :value="value1" :dummy="radiusComputed" :dummy1="zoomcomputed"/>
        </div>
    </div>
    <!-- <div>
      <label>
        <gmap-autocomplete class="form-control" @place_changed="initMarker"></gmap-autocomplete>
        <button @click.prevent="addLocationMarker">Search</button>
      </label>
    </div> -->
    <GMapMap
        :center="center"
        :zoom="zoomcomputed"
        map-type-id="terrain"
        style="width: 100%; height: 300px"
        @click="mark"
      >
      <GMapCircle
        :radius="radius"
        :center="center"
        :draggable='true'
        :editable='false'
        @dragend="handleCircleDragEnd"
    />
      <!-- <gmap-circle ref="circle" :radius="radius" :center="center" :draggable='true' :editable='false' @click="mark" @center_changed="mark">
        </gmap-circle> -->
    </GMapMap>
  </div>
</template>
 
<script>
import Dropdown from './common/Dropdown.vue';
import {toRefs, watch} from 'vue';

export default {
    name: "AddGoogleMap",
    props: ['_radius', 'langlat'],
    
    comments: { Dropdown },
    computed: {
        radiusComputed: {
            set(value) {
                this.radius = Math.round(Number(value * 1609.344));
            },
            get() {
                let radius = 0;
                let value1 = '';
                if (this.radius > 90 && this.radius < 100){
                  radius = .057;
                  value1 = '100 yd';
                } else if (this.radius > 190 && this.radius < 195){
                  radius = .12;
                  value1 = '200 yd';
                } else if (this.radius > 401 && this.radius < 405){
                  radius = .25;
                  value1 = '1/4';
                } else if (this.radius > 800 && this.radius < 810) {
                  radius = .50;
                  value1 = '1/2';
                } else if(this.radius == 0){
                  radius = 0;
                  value1 = 'Region Free';
                } else {
                  radius = Math.round(Number(this.radius / 1609.344));
                  value1 = radius;
                }
                this.$emit("radiusupdated", radius);
                
                this.setDDValues(value1)
                return radius;
            },
        },
        zoomcomputed:{
          set(){
          },
          get(){
            return Number(this.zoom);
          }
        }
    },
    data() {
        return {
            placename: '',
            center: {
                lat: '',
                lng: ''
            },
            zoom: undefined,
            locationMarkers: [],
            locPlaces: [],
            existingPlace: null,
            radius: 0,
            updateonce: false,
            //for the dropdown visible options
            value1:'',
            radiusObj : [
                { 'label':'100 yd'  , 'value':'.06' , 'zoom':'18'},
                { 'label':'200 yd'  , 'value':'.12' , 'zoom':'17'},
                { 'label':'1/4'  , 'value':'.25' , 'zoom' : '15.5'},
                { 'label':'1/2'  , 'value':'.5' , 'zoom' : '14.5'},
                { 'label':'1'  , 'value':'1' , 'zoom' : '13.5'},
                { 'label':'2'  , 'value':'2' , 'zoom' : '12.5'},
                { 'label':'3'  , 'value':'3' , 'zoom' : '12'},
                { 'label':'5'  , 'value':'5' , 'zoom' : '11.5'},
                { 'label':'10'  , 'value':'10' , 'zoom' : '10.5'},
                { 'label':'20'  , 'value':'20' , 'zoom' : '9.5'},
                /* { 'label':'Region Free'  , 'value':'0' , 'zoom' : '5'}, */
            ],
            radiusUnit: 'mi'
        };
    },
    async mounted() {
        this.radius = Math.round(Number(this._radius * 1609.344));
        
        // this.locateGeoLocation();
    },
    watch: {
      '_radius':{
        immediate: true,
        handler(_radius){
            if (_radius){
              this.radius = Math.round(Number(_radius * 1609.344));
            }
          }
        },
        'langlat':{
        immediate: true,
        handler(langlat){
            if (langlat){
              if (langlat.indexOf(',') != -1) {
                if (!langlat.split(',')[0] || !langlat.split(',')[1]) {
                    return false;
                }
                this.center = { lat: parseFloat(langlat.split(',')[0]), lng: parseFloat(langlat.split(',')[1]) };
                this.getAddress(parseFloat(langlat.split(',')[0]), parseFloat(langlat.split(',')[1]));
            }
            }
          }
        },
    },
    methods: {
        initMarker(loc) {
            this.existingPlace = loc;
            this.addLocationMarker();
        },
        onRadiusChange($event){
          this.radiusComputed = $event.value; 
          this.zoom= $event.zoom;
        },
        onExtraEvent(data){
          setTimeout(n=>this.zoom = data[0].zoom, 100);
        },
        setDDValues(val){
          this.value1 = val;
        },
        stopEnterSubmitForm(e){
          if(e.keyCode == 13){
            e.preventDefault();
          }
        },
        addLocationMarker() {
            if(typeof this.existingPlace.geometry == "undefined"){
                return false;     
            }
            if (this.existingPlace) {
                const marker = {
                    lat: this.existingPlace.geometry.location.lat(),
                    lng: this.existingPlace.geometry.location.lng()
                };
                this.locationMarkers.push({ position: marker });
                this.locPlaces.push(this.existingPlace);
                this.$emit("latlongupdated", marker.lat, marker.lng);
                this.onExtraEvent([{"zoom": this.zoom - .000001}]);
                //this.zoom = 8;
                this.center = marker;
                this.existingPlace = null;
                return false;
            }
        },
        mark(event) {
            if (typeof event.lat == "undefined") {
                this.$emit("latlongupdated", event.latLng.lat(), event.latLng.lng());
                this.center = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                };
            }
            else {
                this.$emit("latlongupdated", event.lat(), event.lng());
            }
        },
        handleCircleDragEnd(event){
            this.mark(event);
        },
        locateGeoLocation: function () {
            navigator.geolocation.getCurrentPosition(res => {
                //set only when it is not already set
                if (this.center.lat && this.center.lng) {
                  return false;
                }
                this.center = {
                    lat: res.coords.latitude,
                    lng: res.coords.longitude
                };
                this.$emit("latlongupdated", this.center.lat, this.center.lng);
                this.getAddress(this.center.lat, this.center.lng);
            });
        },
        clearPlacename() {
            this.placename = "";
        },
        getAddress(lat, lon) {
            return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyAr5X7eblwPkDDuB9Xvw8NSsOtzYuXYUOQ`).then(result => result.json()).then(result => {
                if (result && result.results.length) {
                    this.placename = result.results[0].formatted_address;
                }
            });
        }
    },
    components: { Dropdown }
};
</script>
<style lang="scss">
.map-container{

 /*  padding-bottom: 0 !important; */
  
  .location-form-con{
    margin-bottom: 32px;
  }
  .form-group{
      display: flex;
      align-items: center;
      margin-bottom: 0;
      position: relative;
      .form-control{
        padding: 5px 13px !important;
        height: 44px !important;
        border-radius: 5px;
        border: 1px solid #BDBDBD !important;
        background:  #FFF !important;
        position: relative;
        z-index: 1 !important;
      }
      &.location-searchbox-con{
        width: 66%;
        //max-width: 330px;
        .location-searchbox{
          padding-right: 30px !important;
          width: 100%;
          &:focus{
            + .search-actions .clear-icon{
              display: none;
            }
            + .search-actions .search-icon{
              display: block;
            }
          }
        }
      }
      .search-actions{
        position: absolute;
        z-index: 3;
        height: 100%;
        right: 5px;
        display: flex;
        align-items: center;
        span{
          cursor: pointer;
        }
        .search-icon{
          display: none;
        }
      }

  }
  .vue-map-container{
    height: 415px !important;
    @media screen and (max-width: 568px){
      height: 250px !important;
    }
  }
}
</style>