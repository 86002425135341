import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification()

export default {
    success: function (message) {
        notify({
            type: 'success',
            text: message,
            pauseOnHover: true,
        });
    },
    error: function (message) {
        notify({
            type: 'error',
            text: message,
            pauseOnHover: true,
        });
    }
}