import api from './api';

export default {
    users: [],

    async getUsersCount() {
        let response = await api.get("accountHolder/count");
        return response.data?.at(0);
    },

    async getUserProfileData(userId) {
        let response = await api.get(`saas-user/count/${userId}`);
        return response?.data?.data;
    },

    async getUserById(userId){
        let res = await api.get(`saas-user/${userId}`);
        return res.data?.at(0);
    },

    async getAllUsers() {
        let response = await api.get('saas-user');
        this.users = response.data;
        return this.users;
    },

    async getFilteredOperators() {
        if (this.users == '') {
            return this.getOperators();
        }
        return this.users.filter((user) => !!user.SaasOperator);
    },

    async getOperators() {
        let response = await api.get("operators");
        return response.data;
    },

    async addUser(firstName, lastName, email, phone, isAdmin, isOperator, timezone) {
        const userData = {
            firstName,
            lastName,
            email,
            phone,
            isAdmin,
            isOperator,
            timezone
        };

        let response = await api.post('saas-user/invite', userData);
        return response.data;
    },

    async updateUser(firstName, lastName, email, phone, isAdmin, isOperator, timezone, userId) {
        const userData = {
            firstName,
            lastName,
            email,
            phone,
            isAdmin,
            isOperator,
            userId,
            timezone
        };

        let response = await api.post('user/edit', userData);
        return response.data;
    },

    async updateInvitation(firstName, lastName, email, phone, isAdmin, isOperator, inviteId) {
        const invitationData = {
            firstName,
            lastName,
            email,
            phone,
            isAdmin,
            isOperator,
            inviteId,
        };

        let response = await api.post('saas-user/invite/edit', invitationData);
        return response.data;
    },

    async deleteUser(userId) {
        let res = await api.delete(`/saas-user/${userId}`);
        return res.data;
    },

    async transferData(from, to) {
        let res = await api.post("transfer", {
            operatorId: to,
            transferOperatorId: from,
        });
        return res.data;
    },

    async getAccountHolder(){
        let res = await api.get('/saas-user/get/account-Holder');
        return res.data;
    }
};