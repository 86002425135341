<template>
  <div class="c-card-group">
    <div class="c-card" >
      <!-- <IconDetails style="top: 18px; right: 18px;" class="c-card-right-btn" /> -->
       <div :class="{ 'disabled-overlay': !currentUser?.AccountHolder }">
      <div class="flex gap-4 p-0 m-0">
        <div class="c-card-column-3">
          <div class="c-card-item">
            <div class="c-card-title">Account Holder</div>
            <div class="c-card-content content-type-1">
              <p>{{ `${accountHolder?.FirstName ?? "Account"} ${accountHolder?.LastName ?? "Holder"}` }}</p>
              <div class="btn-group" v-if="currentUser?.AccountHolder">
                <button type="button" class="btn-outline">Usage</button>
                <button type="button" class="btn-outline">Payment</button>
              </div>
            </div>
          </div>
          <div class="c-card-item">
            <div class="c-card-title">Admin</div>
            <div class="c-card-content content-type-1">
              <ul>
                <li>• Manages Operators</li>
                <li>• Views All Activity</li>
                <li>• Views Analytics</li>
              </ul>
              <div class="switch-group">
                <!-- <SwitchLg v-model="adminRoleSwitch" @update:modelValue="toggleCurrentUserRole(true)" /> -->
                <SwitchLg v-model="accountHolder.SaasAdmin" @update:modelValue="toggleAccountHolderRole(true)" />
              </div>
            </div>
          </div>
          <div class="c-card-item">
            <div class="c-card-title">Operator</div>
            <div class="c-card-content content-type-1">
              <ul>
                <li>• Manages Contacts</li>
                <li>• Sets Geofence Messages</li>
                <li>• Views Receiver Activity</li>
              </ul>
              <div class="switch-group">
                <SwitchLg v-model="accountHolder.SaasOperator" @update:modelValue="toggleAccountHolderRole(false)" />
              </div>
            </div>
          </div>
        </div>
        <UserDetails style="top:18px; right: 18px;" @success="showSuccess" @error="showError" class="c-card-right-btn"
          :item="accountHolder" />
      </div>
    </div> 
    </div>

    <div class="c-card">
      <div class="c-card-item">
        <div class="c-card-title">Billed Users</div>
        <div class="c-card-content content-type-2">
          <div class="c-content-row">
            <div>Admin</div>
            <div class="c-value">{{ totalAdmins }}</div>
          </div>
          <div class="c-content-row">
            <div>Operator</div>
            <div class="c-value">{{ totalOperators }}</div>
          </div>
          <div class="c-card-column-2">
            <div></div>
            <div class="c-content-row">
              <div>Receivers</div>
              <div class="c-value">{{ totalReceivers }}</div>
            </div>
          </div>
          <div class="c-card-column-2 c-bottom-content">
            <div>
              <button type="button" class="btn-blue-filled" @click="ToggleAddUserPopup('buttonTrigger')">Add
                User</button>
            </div>
            <div class="c-content-row">
              <div>Total</div>
              <div class="c-value">{{ totalAdmins + totalOperators }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="align-center justify-center admin-table">

    <div v-if="successMessage" class="message-delete-alert-con">
      <div class="alert alert-dismissible alert-success">
        <button type="button" class="close" @click="hideUserAddedSuccess" style="color:#828282">&times;</button>
        <span class="alert-headeing">Confirmation</span><br>
        {{ successMessage }}
      </div>
    </div>

    <div v-if="errorMessage" class="message-delete-alert-con">
      <div class="alert alert-dismissible alert-danger">
        <button type="button" class="close" @click="hideErrorMessage" style="color:#828282">&times;</button>
        <span class="alert-headeing">Error</span><br>
        {{ errorMessage }}
      </div>
    </div>

    <S2gTable @refresh="loadUsers" :indeterminate="indeterminate" :items="users" :selectedItemCount="selectedContacts"
      ref="tableRef" :isWhiteIcons="true" :headers="contactheader" :filterItems="[
        { label: 'All', value: 'all' },
        { label: 'None', value: 'none' },
      ]" @toggle-row-select="selectMembers" :thsearch="false" :showCount="true" :isReload="true"
      :totalItems="users.length ?? 0" :totalCountText="'Users'" :totalCountText1="'User'" :dynamicSearchHeight="false"
      :isSelectGlobalCheckBox="isSelectAll" @select-all="selectAll" :page="0">
      <template v-slot:table_body="{ item }">
        <td>
          <div>{{ item.item.name }}</div>
        </td>
        <td>
          <SwitchSm v-model="item.item.admin" @update:modelValue="toggleAdminRole(item)" />
        </td>
        <td>
          <SwitchSm v-model="item.item.operator" @update:modelValue="toggleOperatorRole(item)" />
        </td>
        <td>
          <div style="width: 70px;" class="text-right">{{ item.item.receivers }}</div>
        </td>
        <td style="max-width: 150px; width: 150px">
          <div class="filter-cell" v-if="item.item.status == 'INVITED'">
            <ContactPending /> <span class="status-text">invited...</span>
          </div>
          <div class="filter-cell" v-if="item.item.status == 'ACCEPTED'">
            <ContactVerified /> <span class="status-text">active</span>
          </div>
          <div class="filter-cell" v-if="item.item.status == 'EXPIRE'">
            <UserPlus />
            <button @click="reinviteUser(item.item?.user)">
              <div class="invite-button">
                invite
              </div>
            </button>
          </div>
          <div class="filter-cell" v-if="item.item.status == 'NO ROLE'">
            <USER />
            <div class="invite-button">
              no role
            </div>
          </div>
        </td>
        <td style="max-width: 150px; width: 150px">
          <div class="table-actions" v-if="item.item.id != undefined">
            <UserDetails @success="showSuccess" @error="showError" :item="item.item?.user" />
            <span class="icon-btn">
              <button @click="editUser(item?.item?.user)">
                <EditIcon />
              </button>
            </span>
            <span class="icon-btn">
              <button @click="deleteConfirmUser = item.item">
                <IconArchieve />
              </button>
            </span>
          </div>
        </td>
      </template>
    </S2gTable>
  </div>

  <AddUser v-if="addUserPopup.buttonTrigger" :editUser="editingUser" :title="addUserModalTitle" @success="showSuccess"
    @error="showError" :TogglePopup="() => ToggleAddUserPopup('buttonTrigger')">
  </AddUser>


  <Confirmation v-model="deleteConfirmUser" :data="deleteConfirmUser" @closed="deleteConfirmUser = null"
    :onconfirm="(data) => deleteUser(data)" :body="'Do you really want to delete this user?'" />


</template>

<script setup>
import SwitchLg from '@/components/common/SwitchLg.vue';
import SwitchSm from '@/components/common/SwitchSm.vue';
import { ref, onMounted, computed, reactive } from 'vue';
import S2gTable from "@/components/common/S2gTable.vue";
import EditIcon from '@/components/icons/EditIcon';
import IconArchieve from '@/components/icons/IconArchieve';
import UserPlus from "@/components/icons/UserPlus.vue";
import ContactPending from "@/components/icons/ContactPending.vue";
import ContactVerified from "@/components/icons/ContactVerified.vue";
import UserDetails from '@/components/common/UserDetails.vue'
import AddUser from '@/components/AddUser.vue';
import userService from '@/services/userService';
import { useStore } from 'vuex';
import Confirmation from '@/components/modals/Confirmation.vue';

const adminRoleSwitch = ref(false);
const operatorRoleSwitch = ref(false);
let successMessage = ref(null);
let errorMessage = ref(null);
let editingUser = ref(null);
let totalAdmins = ref(0);
let totalOperators = ref(0);
let totalReceivers = ref(0);
let users = ref([]);
let operators = [];
let accountHolder = reactive({});
let deleteConfirmUser = ref(null);

let store = useStore();
let currentUser = ref(store.getters.user);

const addUserModalTitle = computed(() => {
  if (editingUser.value?.UserInvitationID) {
    return "Edit Invitation";
  } else if (editingUser.value) {
    return "Edit User"
  } else {
    return "Add User"
  }
});


const addUserPopup = ref({
  buttonTrigger: false
});
const ToggleAddUserPopup = (trigger) => {
  addUserPopup.value[trigger] = !addUserPopup.value[trigger]
}

const contactheader = [
  { key: "name", title: "Name", },
  { key: "admin", title: "Admin" },
  { key: "operator", title: "Operator" },
  { key: "receivers", title: "Receivers" },
  {
    key: "status",
    title: "Status",
    class: "pr-0",
    isFilter: true,
    filterItems: [
      { label: 'All', value: 'all' },
      { label: 'None', value: 'none' },
      { label: 'Pending', value: 'pending' },
      { label: 'Verified', value: 'verified' },
      { label: 'Invite', value: 'invite' },
    ],
  },
  { key: "details", title: "Details" },
]
let indeterminate = false;

function selectMembers(e) {
}

function toggleProfilePopup() {
  dispatchEvent(new CustomEvent("toggleProfilePopup"))
}

function editUser(user) {
  editingUser.value = user;
  ToggleAddUserPopup("buttonTrigger");
}

const loadUsers = async () => {
  let fetchedUsers = await userService.getAllUsers();
  users.value = fetchedUsers.map(user => {
    return {
      id: user.UserID,
      name: `${user.FirstName} ${user.LastName}`,
      admin: !!user.SaasAdmin,
      operator: !!user.SaasOperator,
      receivers: user.receiver,
      status: user.Status,
      user: user
    }
  })
  operators = await userService.getFilteredOperators();
}

const loadUsersCount = async () => {
  let data = await userService.getUsersCount();
  totalAdmins.value = data.admin;
  totalOperators.value = data.operator;
  totalReceivers.value = data.receiver;
};

const showSuccess = (message, data) => {
  hideErrorMessage();
  editingUser.value = null
  successMessage.value = message;
  if (data?.refresh) {
    loadUsers();
  }
  setTimeout(() => {
    hideSuccessMessage()
  }, 5000);
}

const showError = (message) => {
  hideSuccessMessage();
  errorMessage.value = message;
  setTimeout(() => {
    hideErrorMessage()
  }, 5000);
}

const deleteUser = async (user) => {
  let userId = user.id;
  try {
    let res = await userService.deleteUser(userId);
    showSuccess(res?.message);
  } catch (error) {
    showError(error?.message);
  }
  deleteConfirmUser.value = null;
}

const toggleAdminRole = async (item) => {
  let user = item?.item?.user;
  user.SaasAdmin = !user.SaasAdmin
  let success = user?.UserInvitationID ? await updateInvitation(user) : await updateUser(user);
  if (!success) {
    user.SaasAdmin = !user.SaasAdmin
    item.item.admin = user.SaasAdmin;
  }
}

const toggleOperatorRole = async (item) => {
  let user = item?.item?.user;
  user.SaasOperator = !user.SaasOperator
  let success = user?.UserInvitationID ? await updateInvitation(user) : await updateUser(user);
  if (!success) {
    user.SaasOperator = !user.SaasOperator
    item.item.operator = user.SaasOperator;
  }
}

const updateUser = async (user) => {
  try {
    if (!Object.hasOwn(user, 'timezone')) {
      user.timezone = user.TimeZone;
    }
    console.log(user);
    let phone = user?.Phone ?? user?.PhoneNumber;
    let res = await userService.updateUser(user.FirstName,
      user.LastName, user.Email,
      phone, !!user.SaasAdmin, !!user.SaasOperator, user?.timezone, user?.UserID);
    showSuccess(res?.message);
    if (user.UserID == currentUser.value?.UserID) {
      store.commit("setUser", user);
    }
    return true;
  } catch (error) {
    showError(error?.message);
    return false;
  }
}

const updateInvitation = async (user) => {
  try {
    let res = await userService.updateInvitation(user.FirstName,
      user.LastName, user.Email,
      user.Phone, !!user.SaasAdmin, !!user.SaasOperator, user?.UserInvitationID);
    showSuccess(res?.message);
    return true;
  } catch (error) {
    showError(error?.message);
    return false;
  }
}

const hideSuccessMessage = () => {
  successMessage.value = false;
}
const hideErrorMessage = () => {
  errorMessage.value = false;
}

const toggleAccountHolderRole = async (isAdmin) => {
  let success = await updateUser(accountHolder);
  if (!success) {
    if (isAdmin) {
      accountHolder.SaasAdmin = !accountHolder.SaasAdmin;
    } else {
      accountHolder.SaasOperator = !accountHolder.SaasOperator;
    }
  }
}

const reinviteUser = async (user) => {
  try {
    let res = await userService.addUser(user.FirstName,
      user.LastName, user.Email,
      user.Phone, !!user.SaasAdmin, !!user.SaasOperator, user?.UserInvitationID);
    showSuccess(res?.message);
    return true;
  } catch (error) {
    showError(error?.message);
    return false;
  }
}

const loadAccountHolder = async () => {
  let user = await userService.getAccountHolder();
  Object.assign(accountHolder, user);
  adminRoleSwitch.value = accountHolder?.SaasAdmin;
  operatorRoleSwitch.value = accountHolder?.SaasOperator;
}


onMounted(() => {
  loadUsers();
  loadUsersCount();
  loadAccountHolder();
});


let isSelectAll = false;
</script>

<style lang="scss" scoped>
.c-card-right-btn {
  position: absolute;
}

.alert-dismissible {
  margin-bottom: 12px;
}

.c-card-group {
  display: grid;
  grid-template-columns: 1fr 440px;
  column-gap: 60px;
}

.c-card-column-3 {
  display: grid;
  grid-template-columns: 266fr 272fr 202fr;
}

.c-card-column-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.content-type-1 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;

  & .btn-group {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    height: 85px;
  }
}

.c-content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #E0E0E0;
  line-height: 20px;

  & .c-value {
    font-size: 24px;
    line-height: 20px;
  }
}

.c-bottom-content {
  margin-top: auto;

  & .c-content-row {
    height: 36px;
    border-bottom: none;
  }
}

.switch-group {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.c-card {
  background-color: #fff;
  padding: 33px 30px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  & .c-card-title {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    padding-bottom: 11px;
    border-bottom: 1px solid #E0E0E0;
    font-weight: 500;
  }

  & .c-card-item {
    display: flex;
    flex-direction: column;
    flex: 1;

    & .c-card-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.btn-outline {
  display: flex;
  width: 100px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #7B8794;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #000;
  transition: 0.2s all ease;

  &:hover {
    background-color: #F2F2F2;
  }
}

.btn-blue-filled {
  display: flex;
  min-width: 100px;
  height: 36px;
  align-items: center;
  padding: 0 17px;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  line-height: 16.41px;
  cursor: pointer;
  color: #fff;
  background-color: #4066CF;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.2s all ease;

  &:hover {
    background-color: #6B87D4;
  }
}

.status-text {
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  font-weight: 500;
}

.admin-table {
  margin-top: 26px;
  margin-bottom: 60px;
}

.invite-button {
  width: 50px;
  height: 20px;
  background: #7B8794;
  font-size: 13px;
  line-height: 16px;
  color: #F2F2F2;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  ;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 1;
  border-radius: 20px;
}
</style>
