import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.VUE_APP_APIDOMAIN}`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("tknds") || '';
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  response => response,
  error => {
    // if (error.response && error.response.status === 401) {
    // }
    return Promise.reject(error?.response?.data);
  }
);

export default api;
